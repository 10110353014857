<template>
  <div class="intro">
    <p class="text" style="line-height: 30px">
      Storing your permits and documents in the cloud (or on your phone) is just
      a tap away!
    </p>
    <div class="features">
      <div id="feature-01" class="feature">
        <img src="../assets/document.svg" alt="document" />
        <h2 class="title">View your permits</h2>
        <p class="text">Always-on cloud access lets you view files any time</p>
      </div>
      <div id="feature-02" class="feature">
        <img src="../assets/cloud.svg" alt="cloud" />
        <h2 class="title">Download your permits</h2>
        <p class="text">
          View your permits even when you don&apos;t have a data connection
        </p>
      </div>
      <div id="feature-03" class="feature">
        <img src="../assets/bell.svg" alt="bell" />
        <h2 class="title">Stay current</h2>
        <p class="text">
          Push notifications will alert you to any permit or file changes
        </p>
      </div>
    </div>
    <div class="buttons">
      <button class="button button-primary" v-on:click="showSupport">
        contact us
      </button>
      <router-link to="/login">
        <button id="signin" class="button button-primary">sign in</button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  created() {
    // Show support modal if linked from mobile
    if (this.$route.query.showhelp === 'true') {
      this.showSupport()
    }
  },
  methods: {
    showSupport() {
      this.$events.$emit('showSupportModal')
    }
  }
}
</script>
<style lang="scss">
.intro {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  margin-top: 32px;

  .title {
    margin: 4px;
    line-height: 30px;
    font-size: 1.1em;
    font-weight: 500;
    color: $white;
    font-family: Poppins;
    text-align: center;
  }

  .text {
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.7);
    font-family: Poppins;
    font-size: 1.2em;
    text-align: center;
    color: $white;
    max-width: 670px;
  }

  .features {
    padding: 32px;
    margin: 0px;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 24px;
  }

  .feature {
    margin: 0px;
    display: flex;
    align-content: center;
    flex-direction: column;

    .text {
      font-size: 0.9em;
      max-width: 240px;
      line-height: 20px;
      margin: 10px;
    }
  }

  .feature img {
    height: 56px;
    margin-bottom: 4px;
  }

  .button-primary {
    font-weight: 600;
    font-size: 19px;
  }

  @media (orientation: landscape) and (max-width: $landscape-width) {
    .features {
      border-radius: 5px;
    }
  }

  @media (orientation: portrait) and (max-width: $mobile-width) {
    .text {
      width: 90%;
    }

    .features {
      flex-direction: column;
      border-radius: 5px;
    }

    .buttons {
      flex-direction: column;
    }
  }
}
</style>
